import '../App.css';

function Section1(text) {
    const { section1Title, section1Text, section1ArticleTitle, section1ArticleSubTitle, section1ArticleAuthor, section1ArticleDate, section1ArticleLink } = text?.text ?? {};

    console.log(text);

    return (
        <section id="section-1">
            <div className="left-column">
                <div className="title-container">
                    <h2>{section1Title}</h2>
                </div>
                {section1Text?.map(item => {
                    return (
                        <p>{item}</p>
                    );
                })}
            </div>
            <div className="right-column">
                <a href={section1ArticleLink} target='_blank' className="article-box">
                    <div className="content">
                        <h2 className="title">{section1ArticleTitle}</h2>
                        <p className="subtitle">{section1ArticleSubTitle}</p>
                        <div className="meta">
                        <span className="author">{section1ArticleAuthor}</span> | <span className="date">{section1ArticleDate}</span>
                        </div>
                    </div>
                </a>
            </div> 
        </section>
    );
}

export default Section1;
