import '../App.css';

function Banner(text) {
    const { bannerImageLeft, bannerImageRight } = text?.text ?? {};

    return (
        <div className="image-container" id="banner">
            <img src={bannerImageLeft} alt="Left Image" className="left-image"/>
            <img src={bannerImageRight} alt="Right Image" className="right-image"/>
        </div>
    );
}

export default Banner;
