import '../App.css';

function Header(text) {
    function scrollToSection(event, id) {
        event.preventDefault(); // Prevent default anchor behavior
        const targetElement = document.getElementById(id);
        const offset = 100; // Adjust the offset as needed
        const top = targetElement.getBoundingClientRect().top + window.pageYOffset - offset;
        window.scrollTo({ top: top, behavior: 'smooth' });
    }
    const { headerTitle, headerSubtitle } = text?.text ?? {};
    return (
        <header>
            {/* Left side: Title and subtitle */}
            <div className="title-container">
                <a href="#endorsements-section" onClick={(event) => scrollToSection(event, 'banner')}>
                    <h1 className="header-text">{headerTitle}</h1>
                    <h2 className="header-text">{headerSubtitle}</h2>
                </a>
            </div>

            {/* Right side: Navigation */}
            <nav>
                <ul>
                <li>
                    <a href="#endorsements-section" onClick={(event) => scrollToSection(event, 'endorsements-section')}>
                    Endorsements
                    </a>
                </li>
                <li>
                    <a href="#about-section" onClick={(event) => scrollToSection(event, 'about-section')}>
                    Priorities
                    </a>
                </li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;
