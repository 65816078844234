import React, { useEffect, useState } from 'react';
import '../Edit.css';

const Edit = () => {
    const [ authorized, setAuthorized ] = useState(false);
    const [ text, setText ] = useState(null);
    const [ section1Paragraphs, setSection1Paragraphs ] = useState([]);
    const [ endorsements, setEndorsements ] = useState([]);
    const [ aboutParagraphs, setAboutParagraphs ] = useState([]);


    const authorize = async (pass) => {
        try {
            const response = await fetch('https://us-central1-marybaumgardner-westlinn.cloudfunctions.net/authorizedToEdit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: {
                        pass: pass,
                    },
                }),
            });
        
            const res = await response;
            console.log(res.text());
    
            if (res.ok) {
                setAuthorized(true)
            }
        } catch (error) {
            console.error('There was a problem with your fetch operation:', error);
        }
    }

    const fetchData = async () => {
        console.log('fetching data');

        try {
          const response = await fetch('https://us-central1-marybaumgardner-westlinn.cloudfunctions.net/getTextFromFirestore');
          
          // Check if the response is OK (status in the range 200-299)
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
  
          const jsonData = await response.json();
          setText(jsonData); // Store the data in 
          setSection1Paragraphs(jsonData.data.section1Text);
          setEndorsements(jsonData.data.endorsementsList);
          setAboutParagraphs(jsonData.data.aboutSectionText);

        } catch (error) {
          console.error('There was a problem with your fetch operation:', error);
        }
      };
    useEffect(() => {
    
        fetchData();
    }, []); 

    const addListItem = (listKey) => {
        if (listKey === 'section1Text') {
            setSection1Paragraphs([...section1Paragraphs, '']);
        }   else if (listKey === 'endorsements') {
            setEndorsements([...endorsements, {"name": "", "title": ""}]);
        }   else if (listKey === 'about') {
            setAboutParagraphs([...aboutParagraphs, '']);
        }
    }

    const handleListItemChange = (event, key, listKey, field) => {
        if (listKey === 'section1Text') {
            const currentState = [...section1Paragraphs];
            currentState[key] = event.target.value;
            setSection1Paragraphs(currentState); // Update the newItem state with the input value
        } else if (listKey === 'endorsements') {
            const currentState = [...endorsements];
            if (field == 'name') {
                currentState[key].name = event.target.value;
            }
            if (field == 'title') {
                currentState[key].title = event.target.value;
            }
        } else if (listKey === 'about') {
            const currentState = [...aboutParagraphs];
            currentState[key] = event.target.value;
            setAboutParagraphs(currentState); // Update the newItem state with the input value
        }
    };

    const save = async () => {
        const response = await fetch('https://us-central1-marybaumgardner-westlinn.cloudfunctions.net/saveTextToFirestore', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                data: {
                    aboutSectionText: aboutParagraphs.filter(item => item !== ""),
                    endorsementsList: endorsements.filter(item => item.name !== ""),
                    section1Text: section1Paragraphs.filter(item => item !== ""),
                    headerTitle: document.getElementById('headerTitle').value,
                    headerSubtitle: document.getElementById('headerSubtitle').value,
                    bannerImageLeft: document.getElementById('bannerImageLeft').value,
                    bannerImageRight: document.getElementById('bannerImageRight').value,
                    section1Title: document.getElementById('section1Title').value,
                    section1Subtitle: "A brighter future",
                    section1ArticleTitle: document.getElementById('section1ArticleTitle').value,
                    section1ArticleSubTitle: document.getElementById('section1ArticleSubTitle').value,
                    section1ArticleAuthor: document.getElementById('section1ArticleAuthor').value,
                    section1ArticleDate: document.getElementById('section1ArticleDate').value,
                    section1ArticleLink: document.getElementById('section1ArticleLink').value,
                    endorsementsTitle: document.getElementById('endorsementsTitle').value,
                    timerTitle: document.getElementById('timerTitle').value,
                    aboutSectionTitle: document.getElementById('aboutSectionTitle').value,
                    section1FacebookLink: document.getElementById('section1FacebookLink').value,
                    section1XLink: document.getElementById('section1XLink').value,
                },
            }),
        });
    
        const res = await response;
        console.log(res.text());

        if (res.ok) {
            fetchData();
        }
    };

    if (!authorized) {
        return (

            <div className="container">
                <h1>Enter password to edit site text</h1>
                <input type="password" id="password" className="text-box"/>
                <button className="submit-button" onClick={() => {
                    authorize(document.getElementById('password').value)
                }}>Submit</button>
            </div>
        )
    }

    else if (authorized && !text) {
        return <div>Loading...</div>;
    }

    else {

    return (
        <div className="container">
                <h1>Edit site text</h1>
                <div className="text-box-container">
                    <label htmlFor="headerTitle">Header Title</label>
                    <textarea id="headerTitle" className="text-box" rows="3" defaultValue={text?.data?.headerTitle}></textarea>
                    
                    <label htmlFor="headerSubtitle">Header Subtitle</label>
                    <textarea id="headerSubtitle" className="text-box" rows="3" defaultValue={text?.data?.headerSubtitle}></textarea>
                    
                    <label htmlFor="bannerImageLeft">Site Banner Left Image</label>
                    <textarea id="bannerImageLeft" className="text-box" rows="3" defaultValue={text?.data?.bannerImageLeft}></textarea>
                    
                    <label htmlFor="bannerImageRight">Site Banner Right Image</label>
                    <textarea id="bannerImageRight" className="text-box" rows="3" defaultValue={text?.data?.bannerImageRight}></textarea>
                    
                    <label htmlFor="section1Title">Main Section Title</label>
                    <textarea id="section1Title" className="text-box" rows="3" defaultValue={text?.data?.section1Title}></textarea>
                    
                    <label>Main Section Paragraphs</label>
                    {section1Paragraphs.map((paragraph, index) => (
                        <textarea key={index} className="text-box" rows="3" defaultValue={paragraph} onChange={(event) => handleListItemChange(event, index, 'section1Text')}></textarea>
                    ))}
                    <button className="svg-button" aria-label="Add" onClick={() => addListItem('section1Text')}>
                        <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2C11.4477 2 11 2.44772 11 3V11H3C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H11V21C11 21.5523 11.4477 22 12 22C12.5523 22 13 21.5523 13 21V13H21C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11H13V3C13 2.44772 12.5523 2 12 2Z" />
                        </svg>
                    </button>
                    <div style={{paddingBottom: "10px"}}>click to add paragraph, to remove paragraph, leave box blank and save</div>
                    
                    <label htmlFor="section1ArticleTitle">Main Section Article Title</label>
                    <textarea id="section1ArticleTitle" className="text-box" rows="3" defaultValue={text?.data?.section1ArticleTitle}></textarea>

                    <label htmlFor="section1ArticleSubTitle">Main Section Article Subtitle</label>
                    <textarea id="section1ArticleSubTitle" className="text-box" rows="3" defaultValue={text?.data?.section1ArticleSubTitle}></textarea>

                    <label htmlFor="section1ArticleAuthor">Main Section Article Author</label>
                    <textarea id="section1ArticleAuthor" className="text-box" rows="3" defaultValue={text?.data?.section1ArticleAuthor}></textarea>

                    <label htmlFor="section1ArticleDate">Main Section Article Date</label>
                    <textarea id="section1ArticleDate" className="text-box" rows="3" defaultValue={text?.data?.section1ArticleDate}></textarea>

                    <label htmlFor="section1ArticleLink">Main Section Article Link</label>
                    <textarea id="section1ArticleLink" className="text-box" rows="3" defaultValue={text?.data?.section1ArticleLink}></textarea>
                    
                    <label htmlFor="endorsementsTitle">Endorsements Section Title</label>
                    <textarea id="endorsementsTitle" className="text-box" rows="3" defaultValue={text?.data?.endorsementsTitle}></textarea>
                    
                    <label htmlFor="timerTitle">Countdown Timer Title</label>
                    <textarea id="timerTitle" className="text-box" rows="3" defaultValue={text?.data?.timerTitle}></textarea>

                    <label>Endorsement Section Name and Title</label>
                    {endorsements.map((endorsement, index) => (
                        <div key={index} className="endorsement-container">
                            <textarea rows="1" defaultValue={endorsement.name} onChange={(event) => handleListItemChange(event, index, 'endorsements', 'name')}></textarea>
                            <textarea rows="1" defaultValue={endorsement.title} onChange={(event) => handleListItemChange(event, index, 'endorsements', 'title')}></textarea>
                        </div>
                    ))}
                    <button className="svg-button" aria-label="Add" onClick={() => addListItem('endorsements')}>
                        <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2C11.4477 2 11 2.44772 11 3V11H3C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H11V21C11 21.5523 11.4477 22 12 22C12.5523 22 13 21.5523 13 21V13H21C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11H13V3C13 2.44772 12.5523 2 12 2Z" />
                        </svg>
                    </button>
                    <div style={{paddingBottom: "10px"}}>click to add image to endorsements carousel, to remove, leave box blank and save</div>
                    
                    <label htmlFor="aboutSectionTitle">About Section Title</label>
                    <textarea id="aboutSectionTitle" className="text-box" rows="3" defaultValue={text?.data?.aboutSectionTitle}></textarea>

                    <label>About Section Paragraphs</label>
                    {aboutParagraphs.map((aboutParagraph, index) => (
                        <textarea key={index} className="text-box" rows="3" defaultValue={aboutParagraph} onChange={(event) => handleListItemChange(event, index, 'about')}></textarea>
                    ))}
                    <button className="svg-button" aria-label="Add" onClick={() => addListItem('about')}>
                        <svg className="svg-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 2C11.4477 2 11 2.44772 11 3V11H3C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H11V21C11 21.5523 11.4477 22 12 22C12.5523 22 13 21.5523 13 21V13H21C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11H13V3C13 2.44772 12.5523 2 12 2Z" />
                        </svg>
                    </button>
                    <div style={{paddingBottom: "10px"}}>click to add paragraph, to remove paragraph, leave box blank and save</div>
                    

                    <label htmlFor="section1FacebookLink">Facebook Link</label>
                    <textarea id="section1FacebookLink" className="text-box" rows="3" defaultValue={text?.data?.section1FacebookLink}></textarea>

                    <label htmlFor="section1XLink">Twitter (X) Link</label>
                    <textarea id="section1XLink" className="text-box" rows="3" defaultValue={text?.data?.section1XLink}></textarea>

                
                </div>
                <button className="submit-button" onClick={() => save()}>Save</button>
        </div>
    );
    }
};

export default Edit;