import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Banner from '../components/Banner';
import Section1 from '../components/Section1';
import Endorsements from '../components/Endorsements';
import About from '../components/About';
import Contact from '../components/Contact';

const Main = () => {
    const [ text, setText ] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch('https://us-central1-marybaumgardner-westlinn.cloudfunctions.net/getTextFromFirestore');
            
            // Check if the response is OK (status in the range 200-299)
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
    
            const jsonData = await response.json();
            setText(jsonData); // Store the data in statecd fun 
          } catch (error) {
            console.error('There was a problem with your fetch operation:', error);
          }
        };
    
        fetchData();
    }, []); 
    
    if (!text) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Header text={text?.data}/>
            <Banner text={text?.data}/>
            <Section1 text={text?.data}/>
            <Endorsements text={text?.data}/>
            <About text={text?.data}/>
            <Contact text={text?.data}/>
        </div>
    );
};

export default Main;