import '../App.css';
import { useState, useEffect } from 'react';

function Endorsements(text) {
    const { endorsementsList, endorsementsTitle, timerTitle } = text?.text ?? {};

    // JavaScript for the carousel
    const [ timerValue, setTimerValue ] = useState({days: '0', hours: '0', minutes: '0'});



    // JavaScript for the countdown timer
    function updateCountdown() {
        const endDate = new Date('November 5, 2024 20:00:00').getTime();
        const now = new Date().getTime();
        const distance = endDate - now;

        if (distance < 0) {
            document.getElementById('timer').innerHTML = "Polls closed!";
            return;
        }

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        setTimerValue({days: String(days).padStart(2, '0'), hours: String(hours).padStart(2, '0'), minutes: String(minutes).padStart(2, '0')});
    }

    useEffect(() => {

        setInterval(updateCountdown, 1000); // Update the countdown every second
        updateCountdown(); // Initial call to display the countdown immediately
    }, []);

        
    return (
        <section id="endorsements-section">
            <div className="left-column">
                <h2 className="carousel-title">{endorsementsTitle}</h2>
                <div className="carousel">
                    <div className="carousel-images">
                        {endorsementsList?.map(endorsement => {
                            return (
                                <div className="endorsements-item">
                                    <p>{endorsement.title}</p>
                                    <h3>{endorsement.name}</h3>
                                </div>
                            )
                        })}
                    </div>
                   
                </div>
            </div>
            <div className="right-column">
                <div id="countdown">
                    <h2 className="countdown-title">{timerTitle}</h2>
                    <div id="timer">
                        <div className="timer-item-container"><div id="days">{timerValue.days}</div><h2>Days</h2></div>
                        <div className="timer-item-container"><div id="hours">{timerValue.hours}</div><h2>Hours</h2></div>
                        <div className="timer-item-container"><div id="minutes">{timerValue.minutes}</div><h2>Minutes</h2></div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Endorsements;
