import '../App.css';

function Section1() {

    const handleSubmit = async (event) => {
        console.log('hi')
        const response = await fetch('https://us-central1-marybaumgardner-westlinn.cloudfunctions.net/saveContactToFirestore', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                data: {
                    firstName: document.getElementById('first-name').value,
                    lastName: document.getElementById('last-name').value,
                    email: document.getElementById('email').value,
                    phone: document.getElementById('phone').value,
                },
            }),
        });
    
        const res = await response;
        console.log(res.text());

        if (res.ok) {
            document.getElementById('first-name').value = ""
            document.getElementById('last-name').value = ""
            document.getElementById('email').value = ""
            document.getElementById('phone').value = ""
        }
    }
    return (
        <section id="contact-section">
            <div className="contact-form">
                <h2>Join our mailing list</h2>

                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="first-name">First Name</label>
                        <input type="text" id="first-name" name="first-name" required/>
                    </div>
                    <div className="form-group" id="last-name-container">
                        <label htmlFor="last-name">Last Name</label>
                        <input type="text" id="last-name" name="last-name" required/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" name="email" required />
                    </div>
                    <div className="form-group" id="phone-container">
                        <label htmlFor="phone">Phone</label>
                        <input type="phone" id="phone" name="phone"/>
                    </div>
                </div>
                <div className="button-container" >
                    <button onClick={() => handleSubmit()}>Submit</button>
                </div>
            </div>
        </section>
    );
}

export default Section1;
